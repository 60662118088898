import Divider from "components/common/divider";
import Footer from "components/footer";
import Navbar from "components/navbar/navbar";
import FlueidDifferentSection from "./flueidDifferentSection";
import HeroSectionSecurity from "./heroSectionSecurity";
import ParallaxSection from "./parallaxSection";
import ProductDevelopmentSection from "./productDevelopmentSection";
import ProductLifecycleSection from "./productLifecycleSection";
import QuotesSection from "./quotesSection";
import TrustExperienceSection from "./trustExpertiseSection";
import TrustPlatformSection from "./trustPlatformSection";
import logoSoc from "../../images/logos/logo-soc.png";
import { SectionContent } from "components/common/sectionContent";

const SecurityPage = () => {
  return (
    <div>
      <Navbar color="blue" offsetLogo />
      <main>
        <div className="sec-hero-parallax-spacing" />
        <ParallaxSection className="sec-hero-parallax-desktop">
          <HeroSectionSecurity />
        </ParallaxSection>
        <ParallaxSection className="ribbon-parallax-mobile sec-bg-blue">
          <SectionContent>
            <div className="award-ribbon margin-auto">
              <img src={logoSoc} width={200} />
              <Divider flexItem />
              <h1 className="txt-white margin-top_none margin-bottom_xsm">98%</h1>
              <p className="txt-white txt-semiBold p-2 margin-none">ALIGNMENT WITH NIST CSF</p>
            </div>
          </SectionContent>
        </ParallaxSection>
        <FlueidDifferentSection />
        <ProductDevelopmentSection />
        <ProductLifecycleSection />
        <TrustExperienceSection />
        <ParallaxSection className="trust-platform-parallax-desktop sec-bg-blue txt-white">
          <TrustPlatformSection />
        </ParallaxSection>
        <QuotesSection />
        <Footer />
      </main>
    </div>
  );
};

export default SecurityPage;
