import React from "react";
import { SectionContent } from "components/common/sectionContent";
import Transition from "components/controls/transition";
import Visible from "components/controls/visible";
import { NavLink } from "react-router-dom";
import { Button } from "components/controls/button";

type Direction = "left" | "right";

interface TimelineItemProps {
  direction: Direction;
  className?: string;
}

const TimelinePeriod: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => (
  <div className={`timeline-period timeline-period-mobileMargin ${props.className || ""} `}>{props.children}</div>
);

const TimelineYear: React.FC<TimelineItemProps> = ({ children, direction }) => (
  <div className={`timeline-yearContainer timeline-yearContainer-${direction}`}>
    <h2 className={`timeline-year timeline-year-${direction}`}>{children}</h2>
  </div>
);

const TimelineTiles: React.FC = (props) => <div className="timeline-tiles">{props.children}</div>;

const TimelineTile: React.FC<TimelineItemProps> = ({ children, direction, className }) => (
  <Visible>
    {(state) => (
      <Transition visible={state.visible} animation="fadeIn" duration={1000} delay={500}>
        <div
          ref={state.ref}
          className={`timeline-tile timeline-tile-mobileMargin timeline-tile-${direction} ${className || ""}`}
        >
          <div className="timeline-paragraph txt-regular">{children}</div>
        </div>
      </Transition>
    )}
  </Visible>
);

export const TimelineSection = () => (
  <section className="timeline-section">
    <SectionContent maxWidthType="section-maxWidth-small">
      <h2>Our deep history and culture of innovation</h2>
      <p>
        Our team’s journey began over 20 years ago when we started automating the title insurance underwriting process,
        architecting four legacy decisioning engines. Today, our proven VOT® solutions fuel streamlined transactions
        from start to finish.
      </p>
      <div className="timeline-container">
        <TimelinePeriod className="timeline-period_margin1995">
          <TimelineYear direction="left">1995</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              We created the title industry’s first online application for property research and review, significantly
              automating a previously disjointed and manual origination and fulfillment process.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod>
          <TimelineYear direction="right">2000</TimelineYear>
          <TimelineTiles>
            <TimelineTile className="timeline-tile_margin2000" direction="left">
              We developed and implemented Move, Inc.’s (formerly Homestore) suite of software products and services to
              help real estate professionals operate more effectively in a digital real estate transaction environment.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod>
          <TimelineYear direction="right">2003</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              We introduced ClosingPoint, which redefined the industry standard for title insurance and escrow
              processing technology by creating, implementing, and continually evolving the industry’s first integrated
              transaction management platform.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2004">
          <TimelineYear direction="left">2004</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              <b>We pioneered the first legacy title decision engine.</b> This premier automated title decision engine
              utilized non-traditional data sources to render title underwriting decisions.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod>
          <TimelineYear direction="right">2007</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              <b>We delivered a second-generation title underwriting engine,</b> incorporating additional data sources
              and enhanced rule sets for a more comprehensive and customized experience.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2009">
          <TimelineYear direction="left">2009</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We co-founded Title365 during a challenging real estate market, quickly scaling the business to become the
              fastest growing national title agency. From 2009 to 2015, we engineered multiple innovative industry
              applications including the Title365 Platform, Title365 Mobile, PropFacts, Xpress Search Engine, Trusted
              Signing and more.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2010">
          <TimelineYear direction="right">2010</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              We achieved three significant industry-first technology milestones:
              <ol>
                <li>Created the title industry’s first native mobile iOS application</li>
                <li>Became the first to deploy the entire enterprise in the cloud</li>
                <li>Achieved the first-ever SSAE 16 SOC II Type II compliance for a title agency</li>
              </ol>
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2013">
          <TimelineYear direction="left">2013</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              <b>We delivered a third-generation decisioning solution</b> that expedited the title insurance, settlement
              services, financial and real estate industries. With an improved design, it is currently operated by a
              large national underwriter.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2015">
          <TimelineYear direction="right">2015</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We became the first in the industry to introduce an innovative end-to-end document signing and closing
              experience, Trusted Signing.
            </TimelineTile>
            <TimelineTile direction="right">
              Our co-founded company, Title365, was acquired by Mr. Cooper (formerly Nationstar), a top national
              mortgage servicer and lender.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_2017-zIndex">
          <TimelineYear direction="left">2017</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              <div className="icon-rocket" />
              <b>Peter Bowman and Peter Richter founded Flueid Software Corporation.</b> Along with Dennis Frandsen, the
              company's chief architect, the founders self-funded the journey as an independent technology company by
              designing and building a fourth proprietary decision engine for large national underwriter.
              {/* TODO JB - keep until page's latest copy updates confirmed...hard to tell if this is meant to be kept or not from the Word document. */}
              {/* <ul>
                <li>
                  <b>Designed and built fourth proprietary decision engine for large national underwriter.</b>
                </li>
                <li>
                  Built on industry experience to deliver an entirely new proprietary decisioning system, which is
                  currently used in the market today.
                </li>
              </ul> */}
            </TimelineTile>

            {/* TODO JB - keep until page's latest copy updates confirmed...hard to tell if this is meant to be kept or not from the Word document. */}
            {/* <TimelineTile direction="right" className="timeline-tile_margin2017">
              <b>Flueid’s leading property and consumer research application.</b> Built <b>Flueid Pro</b> (formerly
              Titlefy Pro), a multi-platform SaaS application providing the industry’s most user-friendly real estate
              property and consumer research capabilities.
              <br />
              <br />
              <NavLink to="/demo">
                <Button>Schedule a Flueid Pro Demo</Button>
              </NavLink>
            </TimelineTile>
            <TimelineTile direction="right">
              <b> Flueid’s independent title data and decisioning platform.</b> Launched the company’s flagship title
              data and decisioning platform, <b>Flueid Decision</b>, to enable VOT within Refinance. The platform is
              title-underwriter agnostic and is approved for used in the title search and examination process by 17 of
              the nation’s top title underwriters.
              <br />
              <br />
              <NavLink to="/demo">
                <Button>Schedule a Flueid Decision Demo</Button>
              </NavLink>
            </TimelineTile> */}
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod>
          <TimelineYear direction="left">2019</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              We developed <b>Flueid Pro</b> (formerly Titlefy Pro), a multi-platform SaaS application enabling
              pre-transaction VOT with the industry’s most user-friendly real estate property and consumer research
              capabilities.
              <br />
              <br />
              <NavLink to="/demo">
                <Button>Schedule a Flueid Pro Demo</Button>
              </NavLink>
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2020">
          <TimelineYear direction="left">2020</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              We launched our flagship title decisioning platform, <b>Flueid Decision</b>, enabling VOT within
              Refinance. The platform is title-underwriter agnostic and is approved for used in the title search and
              examination process by 17 of the nation’s top title underwriters.
              <br />
              <br />
              <NavLink to="/demo">
                <Button>Schedule a Flueid Decision Demo</Button>
              </NavLink>
            </TimelineTile>
            <TimelineTile direction="left">
              We partnered with Aquiline Technology Growth and accepted a strategic investment and strengthened our data
              lake.
            </TimelineTile>
            <TimelineTile className="timeline-tile_marginFlueidBuilt" direction="right">
              We introduced <b>Flueid Built</b>, our managed service and incubator for title providers, lenders,
              PropTech and FinTech platforms.
              <br />
              <br />
              <NavLink to="/demo">
                <Button>Schedule a Flueid Built Analysis</Button>
              </NavLink>
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod className="timeline-period_margin2021">
          <TimelineYear direction="right">2021</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We secured Series A funding from lead investor Aquiline and Commerce Ventures, raising funds to expand the
              Flueid Decision platform, advance business development, and expand integrations with client and industry
              partners.
            </TimelineTile>
            <TimelineTile direction="left">
              We successfully completed our SOC 2 Type I audit, shortly followed by completing our SOC 2 Type II audit
              in just nine months.
            </TimelineTile>
            <TimelineTile direction="left">
              We acquired Vodii, a next-generation real estate software provider, and were awarded a patent for the
              Flueid Decision platform.
            </TimelineTile>
            <TimelineTile direction="right" className="timeline-tile_marginSeriesA">
              We began diversifying the Flueid Decision platform to support all transaction types.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>

        <TimelinePeriod>
          <TimelineYear direction="left">2022</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We were awarded a patent extension on Flueid Decision, broadening the scope of its first patent and
              further validating the platform’s industry innovation as the first and only multi-tenant and agnostic
              system.
            </TimelineTile>
            <TimelineTile direction="right" className="timeline-tile_marginSeriesA">
              We launched our Home Equity product line for Flueid Decision, demonstrating the platform’s versatility
              regardless of market forces, and launched a new solar analyzer to detect solar panels and reduce risk
              during the title search by proactively uncovering potential PACE/HERO liens.
            </TimelineTile>
            <TimelineTile direction="right">
              We received final issuance of our SOC 2 Type 2 unqualified report on Flueid’s Title Underwriting Workflow
              System Related to Flueid Decision.
            </TimelineTile>
            <TimelineTile direction="left">
              We raised Series B to accelerate product diversification and growth in every real estate market cycle.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>
        <TimelinePeriod className="timeline-period_marginToday">
          <TimelineYear direction="right">2023</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="right">
              We released our complete Loss Mitigation product for Flueid Decision to support servicers in identifying
              viable strategies starting as early as the 30-day delinquency mark.
            </TimelineTile>
            <TimelineTile direction="right">
              We introduced our Loan Modification solution for Flueid Decision to support ALTA’s search requirements and
              the creation of recordable documents for issuing MMPs.
            </TimelineTile>
            <TimelineTile direction="right">We raised Series B1 to fuel product expansion.</TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>
        <TimelinePeriod className="timeline-period_marginToday">
          <TimelineYear direction="left">2024</TimelineYear>
          <TimelineTiles>
            <TimelineTile direction="left">
              We released Flueid Decision’s Purchase solution, completing the company’s diversification strategy for
              mortgage originations, with the goal of fueling VOT across the loan life cycle.
            </TimelineTile>
            <TimelineTile direction="left">
              We raised growth financing with LiveOak Ventures, Detroit Venture Partners, Aquiline and Commerce
              Ventures, to scale VOT technologies across the real estate market.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>
        <TimelinePeriod className="timeline-period_marginToday">
          <TimelineYear direction="right">Today</TimelineYear>
          <TimelineTiles>
            <TimelineTile className="timeline-tile_blue" direction="right">
              Our VOT journey is just getting started, with hundreds of thousands of homeowners, buyers, and sellers
              benefiting from Flueid’s technology through enhanced savings and improved customer experiences.
            </TimelineTile>
          </TimelineTiles>
        </TimelinePeriod>
      </div>
    </SectionContent>
  </section>
);
