import styles from "./styles.module.scss";
import housingWireMortgage from "images/logos/logo-housing-wire-mortgage-bw.png";
import housingWireRealEstate from "images/logos/logo-housing-wire-realestate-bw.png";
import housingWireTrendSetters from "images/logos/logo-housing-wire-trendsetters-bw.png";
import housingWireVanguards from "images/logos/logo-housing-wire-vanguards-bw.png";
import inc5000 from "images/logos/logo-inc-5000-bw.png";
import inmanFinance from "images/logos/logo-inman-finance-bw.png";
import inmanProptech from "images/logos/logo-inman-proptech-bw.png";
import octoberResearch from "images/logos/logo-october-research-bw.png";
import { SectionContent } from "components/common/sectionContent";
import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";

export function RecognitionSection() {
  const images = [
    housingWireRealEstate,
    housingWireMortgage,
    housingWireVanguards,
    housingWireTrendSetters,
    inc5000,
    octoberResearch,
    inmanFinance,
    inmanProptech,
  ];

  return (
    <>
      <section className={styles.section}>
        <SectionContent maxWidthType="section-maxWidth-small">
          <h2>Look who’s noticing</h2>
        </SectionContent>
        <br />
        <br />
        <div className={styles.marquee}>
          <div className={styles.marquee_content}>
            {images.map((x) => (
              <img key={`primary-${x}`} src={x} alt="logo" className={styles.logo} />
            ))}
          </div>
          {/* second list creates the repeating effect */}
          <div className={styles.marquee_content} aria-hidden>
            {images.map((x) => (
              <img key={`secondary-${x}`} src={x} alt="logo" className={styles.logo} />
            ))}
          </div>
        </div>
      </section>
      <DashedLineGroup className={styles.lines}>
        {(visible) => (
          <>
            <DashedLine width={214} duration={2.1} delay={0} x={"17vw"} show={visible} />
            <DashedLine width={32} duration={2.1} delay={0.3} x={"22vw"} show={visible} />
            <DashedLine width={78} duration={1.3} delay={0.6} x={"27vw"} show={visible} />
            <DashedLine width={157} duration={0.9} delay={0.8} x={"29vw"} show={visible} />
          </>
        )}
      </DashedLineGroup>
    </>
  );
}
