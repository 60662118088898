import { SectionContent } from "../common/sectionContent";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import { NavLink } from "react-router-dom";
import { Button } from "../controls/button";
import { ReactComponent as IconArrow } from "../../images/icons/icon-arrow.link.svg";

export const LifeAtFlueidSection = () => (
  <section className="section-basicGrid lifeAtFlueid-section">
    <SectionContent maxWidthType="section-maxWidth-small" className="lifeAtFlueid-Content">
      <h2 className="">Life at Flueid</h2>
      <h3 className="subheader-2 txt-semiBold">Our people are our greatest asset.</h3>
      <p className="subheader-para">
        Flueid takes a holistic approach to work and life. We trust our employees to get the job done, but also to take
        the time to rest and recoup. That’s why we pair foundational benefits like health insurance alongside
        lifestyle-oriented policies, because we recognize everyone’s needs are different.
      </p>

      <div className="lifeAtFlueid-benefits">
        <div className="lifeAtFlueid-benefits-group">
          <div className="lifeAtFlueid-benefits-block">
            <div className="benfits-logo logo-culture"></div>
            <div className="lifeAtFlueid-benefits-block-title">Culture-First Teams</div>
            <p className="lifeAtFlueid-benefits-block-para">
              From bi-monthly all-employee huddles to team building trips, we work to foster a culture of trust, respect
              and fun where employees can be themselves, while getting rewarded for their contributions outside of just
              a salary.
            </p>
          </div>
          <div className="lifeAtFlueid-benefits-block">
            <div className="benfits-logo logo-salary"></div>
            <div className="lifeAtFlueid-benefits-block-title">Competitive Salary and 401K</div>
            <p className="lifeAtFlueid-benefits-block-para">
              We offer a competitive base pay and a company-sponsored 401K program so our people are compensated fairly
              for their hard work, and can plan for their future with peace of mind.
            </p>
          </div>
          <div className="lifeAtFlueid-benefits-block">
            <div className="benfits-logo logo-pto"></div>
            <div className="lifeAtFlueid-benefits-block-title">Flexible Time Off</div>
            <p className="lifeAtFlueid-benefits-block-para">
              Whether you simply need a vacation or a situation in life calls you away from work, we offer flexible time
              off so your needs are met. We also celebrate most federal holidays.
            </p>
          </div>
          <div className="lifeAtFlueid-benefits-block">
            <div className="benfits-logo logo-health"></div>
            <div className="lifeAtFlueid-benefits-block-title">Extensive Health Benefits</div>
            <p className="lifeAtFlueid-benefits-block-para">
              We provide medical, dental and vision plans, along with a variety of wellness programs to keep your mind
              and body healthy. We also offer income protection for both short- and long-term disabilities.
            </p>
          </div>
          <div className="lifeAtFlueid-benefits-block">
            <div className="benfits-logo logo-remote"></div>
            <div className="lifeAtFlueid-benefits-block-title">Hub Offices & Remote Work</div>
            <p className="lifeAtFlueid-benefits-block-para">
              We prioritize talent and believe that the best results are achieved where employees feel most comfortable.
              Our hubs are located in buzzing Austin, TX and picturesque Santa Barbara, CA.
            </p>
          </div>
          <div className="lifeAtFlueid-benefits-block">
            <div className="benfits-logo logo-flex"></div>
            <div className="lifeAtFlueid-benefits-block-title">Flexible Work Arrangements</div>
            <p className="lifeAtFlueid-benefits-block-para">
              We work with our people so they can achieve work/life balance while driving effective business results.
              This includes being flexible to your weekly work schedule based on professional and personal priorities.
            </p>
          </div>
        </div>
      </div>
      <div>
        <h2>Our Story</h2>
        <p className="subheader-para-small-margin">
          Founded in 2017, Flueid is establishing VOT® as a standard verification service across all residential real
          estate transactions. Learn more about how we are empowering title agents, title underwriters, mortgage
          lenders, mortgage servicers, and real estate professionals, among others, compress timelines, streamline
          process and improve the overall consumer experience. 
        </p>
        <NavLink to="/about">
          <Button className="btn-black flex flex-center">
            Learn More About Flueid <IconArrow className="icon-arrow_white" />
          </Button>
        </NavLink>
      </div>
      <div className="lifeAtFlueid-grid">
        <div className="lifeAtFlueid-gridItem flex flex-col flex-center">
          <h1>2017</h1>
          <div className="p-1 txt-medium">Year Founded</div>
        </div>
        <div className="lifeAtFlueid-gridItem flex flex-col flex-center">
          <h1>50+</h1>
          <div className="p-1 txt-medium">Employees</div>
        </div>
        <div className="lifeAtFlueid-gridItem flex flex-col flex-center">
          <h1>50+</h1>
          <div className="p-1 txt-medium">Clients</div>
        </div>
        <div className="lifeAtFlueid-gridItem flex flex-col flex-center">
          <div className="icon-infinity" />
          <div className="p-1 txt-medium">Innovations</div>
        </div>
      </div>
    </SectionContent>
  </section>
);
