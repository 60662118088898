import Footer from "../footer";
import Navbar from "../navbar/navbar";
import { CoreValues } from "./coreValues";
import { TeamSection } from "./teamSection";
import { HeroSection } from "../common/heroSection";
import TestimonySection from "./testimonySection";
import { TimelineSection } from "./timelineSection";
import { BackedBySection } from "./backedBySection";
import { OverflowContainer } from "components/controls/overflow";
import { FeaturedStoryAboutSection } from "./featuredStoryAboutSection";
import photoFounders from "images/photos/photo-founders.jpg";

const AboutPageContainer = () => {
  return (
    <div className="aboutPage-container">
      <Navbar color="black" />
      <OverflowContainer>
        <HeroSection className="black aboutPage-heroSection">
          <div className="founders-wrapper">
            <img src={photoFounders} className={"founders"} />
          </div>
          <div className="text-wrapper">
            <div className="subheader-2 txt-semiBold">About</div>
            <h1>Our Company</h1>
            <h3 className="h3-large txt-light">
              At Flueid, we are committed to transparency and to ensuring that insurable title risks are well-understood
              in every residential real estate transaction.
            </h3>
          </div>
        </HeroSection>
        <FeaturedStoryAboutSection />
        <TimelineSection />
        <CoreValues />
        <TestimonySection showDashedLines />
        <TeamSection />
        <BackedBySection />
        <Footer />
      </OverflowContainer>
    </div>
  );
};

export default AboutPageContainer;
