import { HeroSection } from "../common/heroSection";
import DashedLineGroup, { DashedLine } from "../controls/dashedLines";
import { ReactComponent as IconArrowRight } from "../../images/icons/icon-arrow.link.svg";

export const HeroSectionMedia = () => (
  <HeroSection className="black heroSection-media">
    <div className="subheader-2 subheader-2_gray txt-semiBold">Media</div>
    <h1>Flueid News & Stories</h1>
    <div className="flex flex-col-mobile_sm">
      <div className="col-1">
        <h5 className="txt-light">Our take on real estate. Join the conversation and read about our journey.</h5>
        <div className="dashedLines-margin">
          <DashedLineGroup className="dashedLineGroup-1">
            {(visible) => (
              <>
                <DashedLine color="white" width={50} duration={2.5} delay={0} x={"5vw"} show={visible} />
                <DashedLine color="white" width={35} duration={1.5} delay={0.2} x={"15vw"} show={visible} />
                <DashedLine color="white" width={200} duration={1} delay={0.5} x={"18vw"} show={visible} />
                <DashedLine color="white" width={75} duration={0.5} delay={0.7} x={"20vw"} show={visible} />
              </>
            )}
          </DashedLineGroup>
        </div>
        <h5 className="txt-medium flex">
          Please contact our Chief Marketing Officer for media inquires or just to touch base.
          <IconArrowRight className="icon-arrow_white" />
        </h5>
      </div>
      <div className="col-2 margin-top_md">
        <h4 className="txt-regular">Media Contact</h4>
        <div className="img-liz" />
        <div className="flex txt-liz_maxWidth">
          <div className="subheader-2 txt-semiBold title-bold-media">
            Liz Lilly<span className="subheader-2 txt-regular subheader-2_divider">|</span>
          </div>
          <div className="subheader-2 subtitle-bold-media">CMO</div>
        </div>
        <div className="dots-margin">. . . . . . . . . . . . . . . .</div>
        <a href="mailto:media@flueid.com">
          <div className="subheader-2 txt-underline">media@flueid.com</div>
        </a>
        <a href="tel:737-703-3578">
          <div className="subheader-2">(737) 703-3578</div>
        </a>
      </div>
    </div>
  </HeroSection>
);
