import { classNames } from "utilities/classNames";
import styles from "./styles.module.scss";

type VerticalLineSectionSpacingProps = {
  linesOffsetDirection: "left" | "right";
};

export function VerticalLineSectionSpacing(props: VerticalLineSectionSpacingProps) {
  const { linesOffsetDirection } = props;

  return (
    <div className={styles.section}>
      <div className={classNames(styles.box, styles.box_left)} data-offset={linesOffsetDirection === "left"}></div>
      <div className={classNames(styles.box, styles.box_right)} data-offset={linesOffsetDirection === "right"}></div>
    </div>
  );
}
