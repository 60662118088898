import React from "react";
import { AnimateNumber, AnimateNumberProps } from "../../controls/animateNumber";
import Transition from "../../controls/transition";
import Visible from "../../controls/visible";
import styles from "./styles.module.scss";
import { classNames } from "utilities/classNames";

type NumberProps = Pick<AnimateNumberProps, "number" | "duration" | "format" | "frameDuration" | "fractionDigits"> & {
  description: string;
};

export const NumbersSection = () => {
  const numbersData: NumberProps[] = [
    {
      number: 15000000,
      description: "transactions processed by architected technology",
      duration: 3000,
      frameDuration: 8000,
      format(num) {
        return (
          <>
            <sup>+</sup>
            {num.toLocaleString()}
          </>
        );
      },
    },
    {
      number: 1300000,
      description: "transactions supported by the Flueid Decision platform across refinance, home equity and default",
      duration: 3000,
      frameDuration: 8000,
      format(num) {
        return (
          <>
            <sup>+</sup>
            {num.toLocaleString()}
          </>
        );
      },
    },
    {
      number: 30,
      description:
        "VOT® products to support mortgage lenders, mortgage servicers, real estate professionals, title processionals, and title underwriters, among others",
      duration: 3000,
      format(num) {
        return (
          <>
            <sup>+</sup>
            {num.toLocaleString()}
          </>
        );
      },
    },
    {
      number: 20,
      description: "years leading title innovation",
      duration: 3000,
      format(num) {
        return (
          <>
            <sup>+</sup>
            {num.toLocaleString()}
          </>
        );
      },
    },
    { number: 4, description: "legacy decisioning solutions architected by team", duration: 3000 },
    // { value: 12312, description: "RANDOM STUFF OOOOHHHH YYYEAAAA" },
    // { value: 69420, description: "JAH BLAZE UP HIGHER AND HIGHER" },
  ];

  const rowGap = 4;

  // create list of grid row starting positions for each number row, incrementing by the row gap
  // haven't found how todo something like this in pure css yet
  const gridRowStartNumbers = numbersData.reduce<number[]>((acc, _, i) => {
    const prevValue = acc[i - 1] || 0;
    const row = prevValue + rowGap;
    acc.push(row);
    return acc;
  }, []);

  // last row number variable - grid's with auto rows can't use the "-1" value to tell a row to span to end, that only works for grid's with explicitly defined rows
  const lastRowVar = { "--last-row": numbersData.length * rowGap + rowGap } as React.CSSProperties;

  return (
    <section className={classNames("numbers-section", styles.section)}>
      <h2 className={styles.header}>We innovate together</h2>
      <br />
      <div className={styles.grid}>
        {/* connect top right to bottom left background border */}
        <div className={styles.border_top_right}></div>
        <div className={styles.border_bottom_left}></div>

        {/* connected bottom left to top right background border */}
        <div className={styles.border_bottom_right} style={lastRowVar} />
        <div className={styles.border_top_left} style={lastRowVar} />

        {numbersData.map(({ description, ...rest }, i) => {
          // create css variable - the stylesheet will use this for the grid row position as well as for mobile positioning
          const gridRowVar = { "--grid-row": gridRowStartNumbers[i] } as React.CSSProperties;

          return (
            <React.Fragment key={description}>
              <Visible>
                {(state) => (
                  <Transition animation="fadeIn" visible={state.visible}>
                    <div
                      ref={state.ref}
                      style={gridRowVar}
                      className={classNames(styles.number_col_left, styles.number_row, styles.number_header)}
                    >
                      <AnimateNumber show={state.visible} {...rest} />
                    </div>
                  </Transition>
                )}
              </Visible>
              <p
                className={classNames(styles.number_col_right, styles.number_row, styles.number_description)}
                style={gridRowVar}
              >
                {description}
              </p>
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};
