import { SectionContent } from "components/common/sectionContent";
import ThoughtLeadershipSection from "components/common/thoughtLeadershipSection";
import { useThoughtLeadershipSections } from "types/thoughtLeadershipSection";

const QuotesSection = () => {
  const thoughtLeadershipSections = useThoughtLeadershipSections();
  const securityPageQuotesSlug = "security-page-quotes";
  const securityThoughtLeadershipSection = thoughtLeadershipSections.find(
    (x) => x.fields.slug === securityPageQuotesSlug,
  );

  // match the section's background color to that of the contentful section
  const { backgroundColor } = { ...securityThoughtLeadershipSection?.fields };

  return (
    <section className="sec-quotes-section sec-bg-green-dark txt-white" style={{ backgroundColor }}>
      <SectionContent maxWidthType="section-maxWidth-xsm">
        <h3 className="h-max-width">
          <b>Trust in</b> Our Commitment to Consumer Protection & Advocacy{" "}
        </h3>
        <p className="p-1 p-max-width margin-bottom_md">
          We’re a B2B company that is passionate about protecting consumer data. We never monetize consumer data across
          third party entities because we believe the consumer has the right to control and protect their data.
        </p>
      </SectionContent>
      {securityThoughtLeadershipSection && (
        <ThoughtLeadershipSection
          section={securityThoughtLeadershipSection}
          sectionStyles={{ paddingTop: "0", paddingBottom: "0" }}
        />
      )}
    </section>
  );
};

export default QuotesSection;
