import { Entry } from "contentful";
import { IThoughtLeadershipSection } from "types/thoughtLeadershipSection";
import { SectionContent } from "../sectionContent";
import styles from "./styles.module.scss";
import ThoughtLeadershipSlide from "./thoughtLeadershipSlide";
import Slider, { Settings } from "react-slick";
import { classNames } from "utilities/classNames";
import { isLightColor } from "utilities/isLightColor";

type Props = {
  section: Entry<IThoughtLeadershipSection>;
  sectionClass?: string;
  sectionStyles?: React.CSSProperties;
};

const ThoughtLeadershipSection = ({ section, sectionClass, sectionStyles }: Props) => {
  const { backgroundColor, slides } = section.fields;
  const useSlider = Number(slides?.length) > 1;
  const singleSlide = slides?.[0];

  const sliderSettings: Settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    arrows: false,
    dots: true,
  };

  const isBackgroundDark = !isLightColor(backgroundColor);

  return (
    <section
      className={classNames(styles.section, sectionClass)}
      style={{
        ...sectionStyles,
        backgroundColor,
      }}
    >
      <SectionContent maxWidthType="section-maxWidth-xsm">
        {useSlider && (
          <Slider {...sliderSettings} dotsClass={classNames("slick-dots", { [styles.dots_light]: isBackgroundDark })}>
            {slides?.map((slide) => (
              <ThoughtLeadershipSlide key={slide.sys.id} slide={slide} section={section} />
            ))}
          </Slider>
        )}
        {!useSlider && !!singleSlide && <ThoughtLeadershipSlide slide={singleSlide} section={section} />}
      </SectionContent>
    </section>
  );
};

export default ThoughtLeadershipSection;
