import { TextLink } from "components/common/textLink";
import DashedLineGroup, { DashedLine, DashedLineColor } from "components/controls/dashedLines";
import { Entry } from "contentful";
import { IThoughtLeadershipSection } from "types/thoughtLeadershipSection";
import { IThoughtLeadershipSlide } from "types/thoughtLeadershipSlide";
import styles from "./styles.module.scss";

type Props = {
  slide: Entry<IThoughtLeadershipSlide>;
  section: Entry<IThoughtLeadershipSection>;
};

const ThoughtLeadershipSlide = ({ section, slide }: Props) => {
  const { img, author, authorTitle, header, subHeader, message, useAnimatedDashedLines, linkUrl, isLinkExternal } =
    slide.fields;
  const { bigQuoteColor, linkColor, textColor } = section.fields;

  return (
    <article className={styles.editorial_quote_slide} style={{ color: textColor }}>
      <img src={img?.fields.file.url} alt={img?.fields.description} className={styles.quote_photo} />
      <p className={`p-3 ${styles.byline_mobile}`}>
        <span className="txt-semiBold">{author},</span> {authorTitle}
      </p>
      <div className={styles.quote_col}>
        <div className="">
          <h3 className={styles.main_quote}>{header}</h3>
          <p className={`p-1 ${styles.byline_desktop}`}>
            <span className="txt-semiBold">{author},</span> {authorTitle}
          </p>
          {useAnimatedDashedLines && (
            <div className={styles.dashed_lines_container}>
              <DashedLineGroup className={styles.dashed_lines_width}>
                {(visible) => (
                  <>
                    <DashedLine
                      color={textColor as DashedLineColor}
                      width={98}
                      duration={2.1}
                      delay={0}
                      x={"4vw"}
                      show={visible}
                    />
                    <DashedLine
                      color={textColor as DashedLineColor}
                      width={35}
                      duration={1.3}
                      delay={0.3}
                      x={"9vw"}
                      show={visible}
                    />
                    <DashedLine
                      color={textColor as DashedLineColor}
                      width={200}
                      duration={0.9}
                      delay={0.8}
                      x={"12vw"}
                      show={visible}
                    />
                  </>
                )}
              </DashedLineGroup>
            </div>
          )}
        </div>
        {!!subHeader && !!message && (
          <>
            <h3 className={styles.question}>{subHeader}</h3>
            <h3 className={styles.answer}>{message}</h3>
          </>
        )}
        <TextLink external={isLinkExternal} to={linkUrl || ""} colorInline={linkColor}>
          Read More
        </TextLink>
      </div>
    </article>
  );
};

export default ThoughtLeadershipSlide;
