/** Determines if provided hex color is light or dark. */
export function isLightColor(hexColor?: string) {
  try {
    // Remove the '#' if present
    hexColor = hexColor?.replace("#", "") || "";

    // Convert hex to RGB
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);

    // Calculate the perceived brightness
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return true if light, false if dark
    return brightness > 128;
  } catch (error) {
    return false;
  }
}
