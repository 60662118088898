import React from "react";

export interface Testimony {
  name: string;
  title: string;
  img: string;
  quote: React.ReactNode;
}

export const testimonies: Testimony[] = [
  {
    name: "Priscilla Flake",
    title: "Vice President, Flueid Decision Derivatives",
    img: "Priscilla-min.jpg",
    quote: (
      <>
        We are a passionate team of title insurance and real estate industry experts - engineers and business people,
        all with the goal of simplifying the real estate closing process.
      </>
    ),
  },
  {
    name: "Lindsay Good",
    title: "Director of Operations",
    img: "Lindsay-min.jpg",
    quote: (
      <>
        It’s the people and the creativity. We encourage each other, challenge each other, and most importantly, laugh
        with each other. And I feel empowered to try and find new solutions every day to better support our team and our
        clients.
      </>
    ),
  },
  {
    name: "Jesse Billard",
    title: "Software Developer, Engineering Department",
    img: "Jesse-min.jpg",
    quote: (
      <>
        I'm invested in what we're building. The direction of our products matters to me because I feel like there's
        work ownership. If you feel like there's ownership, then you care because it's something you've worked hard on
        to see into fruition. That, and the flexible hours, benefits and team are great, too!
      </>
    ),
  },
  {
    name: "Judy Freeman",
    title: "Vice President, Title Operations and Production",
    img: "Judy-min.jpg",
    quote: (
      <>
        Overall, I love belonging to a forward-thinking, supportive company that realizes employees are their greatest
        asset. And I love the small things that make it that way – from coaching my team and building relationships with
        clients and vendors to finding creative solutions to problems.
      </>
    ),
  },
];
