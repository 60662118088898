import DashedLineGroup, { DashedLine } from "components/controls/dashedLines";

export const OfficesSection = () => (
  <section className="offices-sectionv2-container">
    <div className="offices-sectionv2">
      <div className="austin-office-background office-background"></div>
      <div className="santa-office-background office-background"></div>
      <div className="center-block">
        <div className="center-block__bg"></div>
        <div className="center-block__content default">
          <h2 className="center-block__default-header">Offices located in Austin, TX and Santa Barbara, CA</h2>
        </div>
        <div className="center-block__content austin">
          <div>
            <h2 className="center-block__header">Austin, TX</h2>
            <OfficesLineGroup />
            <p className="center-block__description">
              Known as the Silicon Hills, our south Austin headquarters is home to executives leading our marketing,
              engineering and development teams, alongside those working from home offices across the country. 
            </p>
          </div>
        </div>
        <div className="center-block__content sb">
          <div>
            <h2 className="center-block__header">Santa Barbara, CA</h2>
            <OfficesLineGroup />
            <p className="center-block__description">
              Rooted in the entrepreneurial spirit of California, our Santa Barbara officer powers many of our
              operations, sales and product teammates, alongside several members of the executive team. 
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const OfficesLineGroup = () => (
  <DashedLineGroup className="dashedLineGroup-offices">
    {() => (
      <>
        <DashedLine width={50} duration={2.5} delay={0} x={"1.5vw"} show={true} />
        <DashedLine width={80} duration={1.5} delay={0.2} x={"3vw"} show={true} />
        <DashedLine width={75} duration={1} delay={0.5} x={"6vw"} show={true} />
      </>
    )}
  </DashedLineGroup>
);
